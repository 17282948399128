<template>
  <div class="wrap">
    <Navbar :title="$t('user.personalCenter')"> </Navbar>
    <div class="setting">
      <div class="content">
        <div class="content-item" :class="{ state: NavIndex == index }" v-for="(item, index) in Label" :key="index"
          @click="MousedownEvent(index, item.url)">
          <div>
            <!-- <van-image width="1.2rem" height="1.2rem" round :src="item.img" /> -->
            <span class="item_text">{{ $t(item.text) }}</span>
          </div>
          <div>
            <van-image v-if="index == 0" width="40px" height="40px" round
              :src="userInfo.userimg ? (verConfig.url + item.image) : require('@/assets/Img/myImg/head.png')" />
            <span class="item_value" v-if="index == 1 || index == 2 || index == 3">{{ item.span }}</span>
            <van-icon size="18" color="var(--lioght)" name="arrow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  ActionSheet,
  Button,
  Image,
  Toast,
} from "vant";
import Navbar from "@/components/JNav";
import { maskMiddle } from "@/utils/common"

export default {
  name: "PersonalCenter",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [Toast.name]: Toast,
    Navbar,
  },
  data() {
    return {
      userInfo: {},
      Label: [
        {
          id: "1",
          // img:require('@/assets/Img/myImg/MyOne.png'),
          img: require("@/assets/icon/myInfo/avatar.png"),
          image: "https://img.yzcdn.cn/vant/cat.jpeg",
          url: "Portrait",
          text: `My.PersonalInformation.Page.Avatar`,
        },
        {
          id: "6",
          // img:require('@/assets/Img/myImg/MyTwo.png'),
          img: require("@/assets/icon/myInfo/avatar.png"),
          span: "",
          url: "",
          text: `My.PersonalInformation.Page.Account`,
        },
        {
          id: "2",
          // img:require('@/assets/Img/myImg/MyTwo.png'),
          img: require("@/assets/icon/myInfo/edt.png"),
          span: "",
          url: "Name",
          text: `My.PersonalInformation.Page.RealName`,
        },
        {
          id: "3",
          span: "",
          url: "Email",
          text: `newAdd.Email`,
        },
        // {
        //   id: "3",
        //   // img:require('@/assets/Img/myImg/MyThree.png'),
        //   img: require("@/assets/icon/myInfo/phone.png"),
        //   span: "",
        //   url: "phoneVerify",
        //   text: `My.PersonalInformation.Page.PhoneNumber`,
        // },
        // {
        //   id: "4",
        //   // img:require('@/assets/Img/myImg/MyFour.png'),
        //   img: require("@/assets/icon/myInfo/bank.png"),
        //   url: "add-bank-card",
        //   text: `My.PersonalInformation.Page.BankAccounts`,
        // },
        // {
        //   id: "5",
        //   // img:require('@/assets/Img/myImg/MyFive.png'),
        //   img: require("@/assets/icon/myInfo/pwd.png"),
        //   url: "Password",
        //   text: `My.PersonalInformation.Page.Password`,
        // },
        // {
        //     id:"6",
        //     img:require('@/assets/Img/myImg/MyFive.png'),
        //     url:"PaymentCode",
        //     text:`My.PersonalInformation.Page.TransactionPassword`,
        // },
        // {
        //     id:"7",
        //     img:require('@/assets/Img/myImg/MyFive.png'),
        //     url:"Site",
        //     text:`My.PersonalInformation.Page.ReceivingAddress`,
        // },
      ],
      NavIndex: -1, // 控制选中状态的
    };
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1);
    },
    // 标签鼠标按下
    MousedownEvent(num, url) {
      this.$router.push({ name: url });
    },
    getUserInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo((res) => {
        Toast.clear();
        this.userInfo = res;
        this.Label[0].image = this.userInfo.userimg;
        this.Label[1].span = this.userInfo.userphone;
        this.Label[2].span = this.userInfo.username;
        this.Label[3].span = this.userInfo.email;
      }, (err) => {
        Toast.clear();
      });
    },
  },
  created() {
    this.getUserInfo();
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@comColor: var(--theme);
@wrapPadding: calc(12rem / 16);
@comRadius: calc(10rem / 16);
// @comBG: linear-gradient(#093958, #022c47);
@comBG: var(--bg1);
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}
.wrap {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: var(--bg);

  .setting {
    padding: calc(10rem / 16) calc(15rem / 16);

    .content {
      position: relative;
      border-radius: calc(10rem / 16);
      background-color: rgba(19, 34, 53, .4);

      .state {
        background-color: #ade1f5;
      }

      .content-item {
        padding: calc(15rem / 16) calc(20rem / 16);
        color: var(--light);
        display: flex;
        justify-content: space-between;
        align-items: center;

        >div {
          display: flex;
          flex-direction: row;
          align-items: center;

          .item_text {
            font-size: calc(18rem / 16);
            color: var(--light);
          }

          .item_value {
            width: calc(150rem / 16);
            font-size: calc(14rem / 16);
            color: var(--colors);
            margin-right: calc(4rem / 16);
            .text-overflow();
            text-align: right;
          }
        }
      }
    }
  }
}
</style>